import './polyfills';
import React from 'react';
import ReactDOM from 'react-dom';
import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  // NormalizedCacheObject,
  // gql,
} from 'apollo-boost';
import { ApolloProvider } from 'react-apollo';
import { Provider } from 'react-redux';

import * as serviceWorker from './serviceWorker';

import { HashRouter } from 'react-router-dom';
import './assets/base.scss';
import Main from './DemoPages/Main';
import configureStore from './config/configureStore';
import { BACKEND_API_URL } from './data/constants';

const store = configureStore();
const rootElement = document.getElementById('root');

// Apollo Client Setup
const cache = new InMemoryCache();
const link = new HttpLink({
  uri: BACKEND_API_URL,
});
const apolloClient = new ApolloClient({
  cache,
  link,
  // uri: BACKEND_API_URL,
});

const renderApp = (Component) => {
  return ReactDOM.render(
    <ApolloProvider client={apolloClient}>
      <Provider store={store}>
        <HashRouter>
          <Component />
        </HashRouter>
      </Provider>
    </ApolloProvider>,
    rootElement
  );
};

renderApp(Main);

if (module.hot) {
  module.hot.accept('./DemoPages/Main', () => {
    const NextApp = require('./DemoPages/Main').default;
    renderApp(NextApp);
  });
}
serviceWorker.register();
