import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { createHashHistory } from 'history';
import thunk from 'redux-thunk';

import reducers from '../reducers';
import authSaga from '../reducers/authSaga';

const history = createHashHistory();
const sagaMiddleware = createSagaMiddleware();
const middlewares = [thunk, sagaMiddleware];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  // reducers(history),
  combineReducers({
    ...reducers,
    history,
  }),
  composeEnhancers(applyMiddleware(...middlewares))
);
sagaMiddleware.run(authSaga);

export default function configureStore() {
  // return createStore(
  //   combineReducers({
  //     ...reducers,
  //   }),
  //   {}
  // );

  return store;
}
