const initialState = {
  filterStartDate: null,
  filterEndDate: null,
  filterRegion: null,
  filterCountry: null,
  filterMemberId: null,
};

const filterReducer = (state = initialState, action) => {
  const newState = { ...state };
  if (action.type === 'reset') {
    return state;
  }

  Object.keys(initialState).forEach(function eachKey(key) {
    if (key === action.type) {
      newState[key] = action.payload;
      state = newState;
    }
    // return state;
  });
  return state;
};

export default filterReducer;
