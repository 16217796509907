import { all, takeEvery, put, call } from 'redux-saga/effects';
import { toast, Bounce } from 'react-toastify';
import {
  samlLogin,
  samlLogout,
  authStatusCheck,
  //   getUserDetails,
} from '../services/authService';
import authActions from './authActions';

const notify = (message, type) =>
  toast(message, {
    transition: Bounce,
    closeButton: true,
    autoClose: 3000,
    position: 'top-center',
    type: type,
  });

// SAML
export function* SAML_LOGIN() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  });
  const success = yield call(samlLogin);
  if (success) {
    notify('Login Successful!', 'success');
    yield put({
      type: 'user/SAML_GET_CURRENT_ACCOUNT',
    });
  }
}

export function* SAML_LOGOUT() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      id: '',
      firstName: '',
      lastName: '',
      role: '',
      email: '',
      organisation: '',
      avatar: '',
      token: '',
      authorized: false,
      loading: false,
    },
  });
  yield call(samlLogout);
}

export function* SAML_GET_CURRENT_ACCOUNT() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  });
  const authCheck = yield call(authStatusCheck);
  // console.log(authCheck);
  if (authCheck) {
    switch (authCheck.status) {
      case 'login':
      case 'LoggedOut':
      case 'NoSessCookie':
      case 'NoSession':
      case 'ExpiredCookie':
        // clear details in redux
        yield put({
          type: 'user/SET_STATE',
          payload: {
            id: '',
            firstName: '',
            lastName: '',
            role: '',
            email: '',
            organisation: '',
            avatar: '',
            token: '',
            authorized: false,
            loading: false,
          },
        });
        // break;

        yield put({
          type: 'user/SAML_LOGIN',
        });
        break;
      case 'OK':
        // eslint-disable-next-line
        const {
          id,
          firstName,
          lastName,
          organisation,
          email,
          avatar,
        } = authCheck.data;
        // eslint-disable-next-line
        const { token } = authCheck;
        yield put({
          type: 'user/SET_STATE',
          payload: {
            id,
            firstName,
            lastName,
            email,
            organisation,
            avatar,
            role: organisation === 'Fleet Forum' ? 'Administrator' : 'Member',
            token,
            authorized: true,
          },
        });
        break;

      default:
        yield put({
          type: 'user/SET_STATE',
          payload: {
            loading: false,
          },
        });
        break;
    }
  }
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  });
}

export default function* authSaga() {
  yield all([
    takeEvery(authActions.SAML_LOGIN, SAML_LOGIN),
    takeEvery(authActions.SAML_LOGOUT, SAML_LOGOUT),
    takeEvery(authActions.SAML_GET_CURRENT_ACCOUNT, SAML_GET_CURRENT_ACCOUNT),
    SAML_GET_CURRENT_ACCOUNT(), // run once on app load to check user auth
  ]);
}
