import { BACKEND_URL } from '../data/constants';

export async function samlLogin() {
  console.log('login...');
  try {
    const loginReq = await fetch(`${BACKEND_URL}/login`);
    console.log('loginurl', loginReq);
    if ((await loginReq.status) !== 200 && (await loginReq.status) !== 201) {
      throw new Error('SAML Login Failed');
    }
    const loginRes = await loginReq.json();
    // console.log(await loginRes)
    window.location.href = loginRes;
  } catch (error) {
    console.log(error);
  }
}

export async function samlLogout() {
  try {
    window.location.href = `${BACKEND_URL}/logout`;
  } catch (error) {
    console.log(error.message);
  }
}

export async function authStatusCheck() {
  try {
    const statusReq = await fetch(`${BACKEND_URL}/getUserDetails`, {
      credentials: 'include',
    });
    const statusRes = await statusReq.json();
    return statusRes;
  } catch (err) {
    console.log(err);
  }
}

export async function getUserDetails() {
  try {
    const userReq = await fetch(`${BACKEND_URL}/getUserDetails`, {
      credentials: 'include',
    });
    const userRes = await userReq.json();
    if (userRes.status === 'loggedOut') {
      // clear user details in local storage
    }

    if ((await userReq.status) !== 200 && (await userReq.status) !== 201) {
      throw new Error('Cannot get user details');
    } else if ((await userReq.status) === 301) {
      return { access: 'Denied' };
    }

    const userDetails = await userReq.json();
    // console.log(userDetails)
    if (userDetails.status === 302 || userDetails.status === 'login')
      return null;

    // const userDetails = await userReq.json()
    // console.log(userDetails)
    return userDetails;
  } catch (error) {
    console.log(error);
  }
}
