import actions from './authActions';

const initialState = {
  id: '',
  name: '',
  role: '',
  firstName: '',
  lastName: '',
  avatar: '',
  authorized: false,
  loading: false,
  token: '',
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
